export const translations = {
  de: {
    "preventReload": "Neuladen der Seite verhindern",
    "tagTitle": "Tag-Titel",
    "category": "Kategorie",
    "template": "Entwurf",
    "callCount": "Ausgelöst: {{count}}",
    "backToOverview": "Zurück zur Übersicht",
    "tagInfoTitle": "Tag",
    "trigger": "Trigger:",
    "noTrigger": "Kein Trigger",
    "cssSel": "CSS Selektor:",
    "tagCopySuccess": "Tag-Code kopiert!",

    "categories": {
      "no_consent" : "Keine",
      "required"   : "Notwendig",
      "marketing"  : "Marketing",
      "performance": "Funktionell",
      "statistics" : "Statistik"
    },

    "cssType": {
      "EVENT_TYPE_PAGE_VIEW": "Seitenaufruf",
      "EVENT_TYPE_CLICK": "Element Klick",
      "EVENT_TYPE_VIEW": "Element sichtbar",
    },
  },
  en: {
    "preventReload": "Prevent page reload",
    "tagTitle": "Tag-Title",
    "category": "Category",
    "template": "Template",
    "callCount": "Triggered: {{count}}",
    "backToOverview": "Back to overview",
    "tagInfoTitle": "Tag",
    "trigger": "Trigger:",
    "noTrigger": "No Trigger",
    "cssSel": "CSS Selector:",
    "tagCopySuccess": "Tag code copied!",

    "categories": {
      "no_consent" : "None",
      "required"   : "Essential",
      "marketing"  : "Marketing",
      "performance": "Functional",
      "statistics" : "Statistics"
    },

    "cssType": {
      "EVENT_TYPE_PAGE_VIEW": "Page view",
      "EVENT_TYPE_CLICK": "Element click",
      "EVENT_TYPE_VIEW": "Element visible",
    },
  }
};