import iconBing from './img/tags/bing.png';
import iconCustom from './img/tags/custom.png';
import iconEtConversion from './img/tags/etConversion.png';
import iconEtDimension from './img/tags/etDimension.png';
import iconEtEvent from './img/tags/etEvent.png';
import iconEtOnsite from './img/tags/etOnsite.png';
import iconGa from './img/tags/ga.png';
import iconGaConversion from './img/tags/gaConversion.png';
import iconLinkedin from './img/tags/linkedin.png';
import iconLinkedinConversion from './img/tags/linkedinConversion.png';
import iconMeta from './img/tags/meta.png';
import iconTiktok from './img/tags/tiktok.png';

export default {
  'bing': iconBing,
  'custom': iconCustom,
  'etConversion': iconEtConversion,
  'etDimension': iconEtDimension,
  'etEvent': iconEtEvent,
  'etOnsite': iconEtOnsite,
  'ga': iconGa,
  'gaConversion': iconGaConversion,
  'linkedin': iconLinkedin,
  'linkedinConversion': iconLinkedinConversion,
  'meta': iconMeta,
  'tiktok': iconTiktok
};