import html from 'bundle-text:./overlay.html';
import {translations} from './lang.js';
import tagIcons from './tagIcons.js';

import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('xml', xml);

// @TODO: get from e.js
const BASE_PATH = '/v1/etdebug';
const BASE_URL = (new URL(et_config.debugCodeUrl || location.href)).origin + BASE_PATH;
const SESSION_STORAGE_FLAG = "et_debugOverlayOpen";

// parcel assumes a relative load in the HTML, since we actually inject it via JS this doesn't work.
// patch font URL here
let HTML_DATA = html
  .replaceAll('url("soehne', `url("${BASE_URL}/soehne`)
  .replaceAll('url(soehne', `url(${BASE_URL}/soehne`)

let lang = 'de';
const LANG = translations[lang];

const langKeys = Object.keys(LANG);
for (let key of langKeys) {
  HTML_DATA = HTML_DATA.replace(new RegExp(`{{${key}}}`, 'g'), LANG[key]);
}

/**
 * 
 * @param {HTMLElement} elem 
 * @param {OverlayAlign} type 
 */
function setAlignment(elem, type) {
  elem.style.left = type === 'left' ? '4px' : 'auto';
  elem.style.right = type == 'right' ? '4px' : 'auto';
}

/** @type {TagInfo} */
const TAG_INFO = {};

/**
 * @param {*} tagId 
 * @returns {TagInfo}
 */
function getTagInfo(tagId) {
  if(!TAG_INFO[tagId]) {
    TAG_INFO[tagId] = {
      count: 0,
      lastCode: ""
    };
  }
  return TAG_INFO[tagId];
}

// Toggle between main view (tag list) & tag detail view
function toggleTagDetail(showDetail) {
  const overlay = document.getElementById('et_debugOverlay');
  overlay.querySelector('.view_main').style.display = showDetail ? 'none' : 'flex';
  overlay.querySelector('.view_tag').style.display = showDetail ? 'flex' : 'none';
}

/**
 * Updates the UI for the tag list, can be limited to a specific tagId
 * @param {number|undefined} tagIdFilter
 */
function updateUiOverview(tagIdFilter) {
  const tagElements = document.querySelectorAll('#et_debugOverlay div[data-tag-id]');
  for(var elem of tagElements) {
    const tagId = elem.getAttribute('data-tag-id');
    if(tagIdFilter && tagId != tagIdFilter)continue;

    const tagInfo = getTagInfo(tagId);

    let text = LANG.callCount.replace('{{count}}', tagInfo.count);
    const elemCount = elem.querySelector('.tagInfoCount');
    elemCount.textContent = text;
    elemCount.style.display = tagInfo.count == 0 ? 'none' : 'inline-block';

    const oldAnimId = elemCount.getAttribute('data-animation-id');
    if(oldAnimId)clearTimeout(oldAnimId);

    elemCount.style.animation = null;
    setTimeout(() => {
      elemCount.style.animation = 'tagCountAnim 0.4s ease-in-out forwards';
    }, 0);
    
    const id = setTimeout(() => elemCount.style.animation = '', 450);
    elemCount.setAttribute('data-animation-id', id);
  }
}

/**
 * Updates the UI for the tag detail view
 * @param {number} tagId 
 */
function updateUiTagDetails(tagId) {
  const tagInfo = getTagInfo(tagId);
  const tag = et_tagManagerEntries.find(e => e.id == tagId);

  const elem = document.querySelector('.tagInfoContainer');
  const elemCode = elem.querySelector('.tagCode');
  elemCode.textContent = tagInfo.lastCode || tag.content;
  elemCode.removeAttribute('data-highlighted');
  elem.style.display = 'block';

  const elemTrigName = document.querySelector('.triggerContainer .triggerName span');
  const elemTrigType = document.querySelector('.triggerContainer .triggerType');
  const elemTrigSel = document.querySelector('.triggerContainer .triggerSel span');

  const cssSelVisible = tagInfo.trigger && tagInfo.trigger.eventType !== 'EVENT_TYPE_PAGE_VIEW';
  document.querySelector('.triggerContainer .triggerSel').style.display = cssSelVisible ? 'block' : 'none';

  if(tagInfo.trigger) {
    elemTrigName.textContent = tagInfo.trigger.title;
    elemTrigType.textContent = LANG.cssType[tagInfo.trigger.eventType];
    elemTrigSel.textContent = tagInfo.trigger.cssSelector;
  } else {
    elemTrigName.textContent = LANG.noTrigger;
    elemTrigType.textContent = '';
    elemTrigSel.textContent = '-';
  }


  hljs.highlightAll();
}

function closeOverlay()
{
  const elem = document.getElementById("et_debugOverlay");
  if(elem)elem.remove();
  sessionStorage.removeItem(SESSION_STORAGE_FLAG);
}

/**
 * Toggles preventing navigation on all links
 * @param {*} prevent if true, no links will work
 */
function updatePreventNavigation(prevent) 
{
  const links = document.querySelectorAll('a');
  for(var link of links) {
    link.onclick = prevent ? (e) => {
      e.preventDefault();
      e.stopPropagation();
    } : null;
  }
}

/**
 *  Hook that gets called whenever a new tag is inserted from the tag manager
 * @param {*} code string of replaced tag code
 * @param {*} entry 
 * @param {*} element 
 * @param {Trigger|undefined} trigger 
 * @returns {boolean} actually inject tag or not (false = prevent injection)
 */
window.et_tagManagerInsertEntryHook = function(code, entry, element, trigger) 
{
  const tagInfo = getTagInfo(entry.id);
  tagInfo.count++;
  tagInfo.lastCode = code;
  tagInfo.trigger = trigger;
  updateUiOverview(entry.id);
  return false;
};

const run = () => {
  // wait until tag entires are loaded (t.js), to be safe, the hook is already installed before it
  if(typeof(et_tagManagerEntries) === 'undefined') {
    setTimeout(run, 50);
    return;
  }
  sessionStorage.setItem(SESSION_STORAGE_FLAG, '1');

  const elemBase = document.body.firstElementChild || document.body;
  elemBase.insertAdjacentHTML('beforebegin', HTML_DATA);

  const overlay = document.getElementById('et_debugOverlay');

  // Align icons, they will move the overlay to the left, center or right
  const alignIcons = overlay.querySelectorAll(".alignIcons")
  for(var icon of alignIcons) {
    icon.children[0].onclick = () => setAlignment(overlay, 'left');
    icon.children[1].onclick = () => setAlignment(overlay, 'right');
    icon.children[2].onclick = () => closeOverlay();
  }

  // toggle for page-reload prevention
  const preventReload = overlay.querySelector('.overlayToggleWrapper input');
  preventReload.onclick = () => {
    updatePreventNavigation(preventReload.checked);
  };

  // tag-detail view, copies the current tag code to clipboard
  const copyIcon = overlay.querySelector('.tagCopyIcon');
  copyIcon.onclick = () => {
    const elemCode = overlay.querySelector('.tagCode');
    const code = elemCode.textContent;
    navigator.clipboard.writeText(code);
    
    copyIcon.classList.add('tagCopied');
    setTimeout(() => copyIcon.classList.remove('tagCopied'), 1000 * 4);
  }

  // navigate back from tag detail view to main view
  const detailBack = overlay.querySelector('.overlayTitleBack');
  detailBack.onclick = () => toggleTagDetail(false);

  // create list of all tags here, we only need to do it once as all tags are always loaded
  var tagContainer = overlay.querySelector('.tagContainer');
  var tagEntryTpl = overlay.getElementsByTagName("template")[0];

  for(var tag of et_tagManagerEntries) 
  {
    const tagNode = tagEntryTpl.content.cloneNode(true);
    
    const mainNode = tagNode.querySelector("div");
    mainNode.setAttribute('data-tag-id', tag.id);
    let tagId = tag.id;

    // clicking on a tag opens the detail view, and updates the UI
    mainNode.onclick = () =>  {
      const currTagNode = overlay.querySelector(`div[data-tag-id="${tagId}"]`).cloneNode(true);
      overlay.querySelector('.selectedTag').innerHTML = '';
      overlay.querySelector('.selectedTag').appendChild(currTagNode);

      updateUiTagDetails(tagId);
      toggleTagDetail(true);
    };
    tagNode.querySelector('.tagTitle').textContent = tag.title || '-';
    tagNode.querySelector('.tagCategory').textContent = LANG.categories[tag.category];

    const img = tagIcons[tag.template] || tagIcons.custom;
    tagNode.querySelector('.tagIcon').src = img;

    if(!tag.is_draft) {
      tagNode.querySelector('.tagInfoState').remove();
    }

    tagNode.querySelector('.tagInfoCount').style.display = 'none';

    tagContainer.appendChild(tagNode);
  }

  updateUiOverview(); // update once here, there is a chance a tag was inserter before this function was called
};

if (document.readyState !== 'loading') {
  run();
} else {
  document.addEventListener('DOMContentLoaded', () => run());
}